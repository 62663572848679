import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importa il hook useNavigate
import ClickableCard from './category'; // Assicurati che questo import sia corretto

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Inizializza il hook useNavigate

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
                method: 'GET',
                headers: {
                  'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
                  'Content-Type': 'application/json'
                }
              });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleCardClick = (id) => {
    navigate(`/category/${id}`); // Naviga alla rotta /category/:id
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {categories.map((category) => (
        <Grid item key={category.id}>
          <ClickableCard
            image={category.urlimg} // Puoi sostituire con l'immagine reale se disponibile
            title={category.name}
            onClick={() => handleCardClick(category.id)} // Passa l'ID alla funzione di gestione del click
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Category;
