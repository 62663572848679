import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Button, Link, Menu, MenuItem, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuIcon from '@mui/icons-material/Menu'; // Icona per il menu a discesa
import { Link as RouterLink } from 'react-router-dom'; // Link di react-router-dom
import { useNavigate } from 'react-router-dom'; // Hook per la navigazione
import './css/menu.css'; // Importa il file CSS per gli stili
import CryptoJS from 'crypto-js';

function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}



// Definisci il componente MenuComponent
const MenuComponent = () => {
  const [randomCard, setRandomCard] = useState(null); // Modifica da [] a null
  const navigate = useNavigate(); // Hook per la navigazione
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Gestore per chiudere il menu a discesa
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchRandomCard = async () => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/random`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRandomCard(data);
      
      if (data.id) {
           let param = md5ToBase64(`https://hiddenvideos.vip/card/${data.id}`)
        navigate(`/card/${data.id}?u=${param}`);
      }
    } catch (error) {
      console.error('Error fetching random card:', error);
    }
  };
  const [value, setValue] = useState('');

  // Valore predefinito da usare se non è presente nel localStorage
  const defaultValue = 0;

  // Leggi o imposta il valore nel localStorage all'inizio
  useEffect(() => {
    // Leggi il valore dal localStorage
    const storedValue = localStorage.getItem('fd064b41a01ca9a573feb720493a334d');

    if (storedValue) {
      // Se il valore è presente, impostalo nello stato
      setValue(storedValue);
    } else {
      // Se il valore non è presente, imposta il valore predefinito
      localStorage.setItem('fd064b41a01ca9a573feb720493a334d', defaultValue);
      setValue(defaultValue);
    }
    const storedValue2 = localStorage.getItem('e5e486979728b4f6be6401d24967ecc0');

    if (!storedValue2) {
      localStorage.setItem('e5e486979728b4f6be6401d24967ecc0', 0);
      setValue(0);

    }
  }, []);

    return (
        <AppBar position="static" className="appBar">
            <Toolbar className="toolbar">
                <div className="desktopMenu">
                <Link component={RouterLink} to="/" underline="none" color="white">
                    <IconButton color="inherit">
                        <img src={`${process.env.PUBLIC_URL}/favicon.jpeg`} alt="HiddenVideos.vip Logo" style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Link>
                    <Link component={RouterLink} to="/" underline="none" color="white" >
                      HiddenVideos
                    </Link>
                    <Link component={RouterLink} to="/tos" underline="none">
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: 2,
                                backgroundColor: '#000000',
                                '&:hover': {
                                    backgroundColor: '#1e1e1e',
                                    borderColor: '#333',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            TOS & Privacy
                        </Button>
                    </Link>
                    <Link component={RouterLink} to="/category" underline="none">
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: 2,
                                backgroundColor: '#000000',
                                '&:hover': {
                                    backgroundColor: '#1e1e1e',
                                    borderColor: '#333',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            Category
                        </Button>
                    </Link>
                    <Link component={RouterLink} to="/how-to-get-coins" underline="none">
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: 2,
                                backgroundColor: '#000000',
                                '&:hover': {
                                    backgroundColor: '#1e1e1e',
                                    borderColor: '#333',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            How To Get Coins
                        </Button>
                    </Link>
                    <Button
                      variant="contained"
                      onClick={fetchRandomCard}
                      sx={
                      {marginLeft: 2,backgroundColor: '#000000',
                      '&:hover':
                        { backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse
                        borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile)
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'},
                      border: '1px solid white'
                      }}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }} width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dices h-4 w-4 inline-block mr-2 "><rect width="12" height="12" x="2" y="10" rx="2" ry="2"></rect><path d="m17.92 14 3.5-3.5a2.24 2.24 0 0 0 0-3l-5-4.92a2.24 2.24 0 0 0-3 0L10 6"></path><path d="M6 18h.01"></path><path d="M10 14h.01"></path><path d="M15 6h.01"></path><path d="M18 9h.01"></path></svg>
                      Random
                    </Button>


                </div>
                <div className="buttonsContainer" style={{ display: 'flex', alignItems: 'center' }}>
    <Link component={RouterLink} to="/how-to-get-coins" underline="none">
    <Box
      className="coinButton"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent', // Background trasparente per il Box
        borderRadius: '4px', // Per avere angoli leggermente arrotondati, se necessario
        mr: 2,  // Margine a destra per separare i due bottoni
        padding: 1
      }}
    >
      <span>{value}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="gold" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"></path>
        <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path>
        <path d="M12 18V6"></path>
      </svg>
    </Box>
    </Link>
    <IconButton
      className="telegramButton"
      onClick={() => window.open('https://t.me/OFPlusHub', '_blank')}
    >
      <TelegramIcon sx={{ color: '#24A1DE' }} />
    </IconButton>
  </div>

          <div className="mobileMenu">
          <Link component={RouterLink} to="/" underline="none" color="white" sx={{mr:6}}>
            HiddenVideos
          </Link>
         {/* Mostra il menu mobile */}
         <IconButton edge="start" color="inherit" aria-label="menu" sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }} onClick={handleMenuClick}>
           <MenuIcon />
         </IconButton>
         <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
           <MenuItem onClick={handleMenuClose} component={RouterLink} to="/" underline="none">
             HiddenVideos
           </MenuItem>
           <MenuItem onClick={handleMenuClose} component={RouterLink} to="/tos" underline="none">
             TOS & Privacy
           </MenuItem>
           <MenuItem onClick={handleMenuClose} component={RouterLink} to="/category" underline="none">
             Category
           </MenuItem>
           <MenuItem onClick={handleMenuClose} component={RouterLink} to="/how-to-get-coins" underline="none">
             How To Get Coins
           </MenuItem>
           <MenuItem onClick={fetchRandomCard}>
             Random
           </MenuItem>
         </Menu>
       </div>
            </Toolbar>
        </AppBar>
    );
};

// Esporta il componente MenuComponent
export default MenuComponent;
