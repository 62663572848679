import React from 'react';
import { Link } from '@mui/material';

const EmailLink = ({ email, children, ...props }) => {
    return (
        <Link href={`mailto:${email}`} color="primary" {...props}>
            {children || email}
        </Link>
    );
};

export default EmailLink;
