// component/CategoryDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import CustomCard from './card'; // Assicurati che il percorso sia corretto

const CategoryDetail = () => {
  const { id } = useParams(); // Ottieni l'ID della categoria dalla URL
  const [cards, setCards] = useState([]); // Inizializzato come array vuoto
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCards = async () => {
      try {


      const response = await fetch(`https://hiddenvideos.vip/api/cards-by-category/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });


        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data.cards)) {
          setCategoryName(data.categoryName);
          setCards(data.cards);
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
    <Typography
      variant="h4"
      gutterBottom
      sx={{ color: 'white', textAlign: 'center', marginY: 4,
      fontWeight: 'bold',
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textDecorationStyle: 'wavy',
      textDecorationColor: '#00AFF0', // Usa il colore principale
      marginBottom: 2, // mb-4 in Tailwind
    }} // Centra il testo e aggiungi margine verticale
    >
      {categoryName}</Typography>
      <Grid container spacing={2} justifyContent="center">
        {cards.length > 0 ? (
          cards.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <CustomCard
                title={card.title}
                category={card.category}
                time={card.time}
                imageUrl={card.imageUrl}
                id={card.id}
              />
            </Grid>
          ))
        ) : (
          <Typography>No cards found for this category.</Typography>
        )}
      </Grid>
    </Container>
  );
};

export default CategoryDetail;
