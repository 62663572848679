import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

const SiteLink = ({ children, ...props }) => {
    return (
        <Link component={RouterLink} to="/" underline="none" color="primary" {...props}>
            {children}
        </Link>
    );
};

export default SiteLink;
