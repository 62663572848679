import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container} from '@mui/material';

import CardStep from './component/cardstep'; // Crea questo componente per la vista dettagliata della card
import TermsOfService from './component/tos';
import Main from "./component/main"
import MenuComponent from './component/menu';
import Footer from './component/footer';
import Category from './component/catlist';
import CategoryDetail from './component/catid';
import Htgc from './component/htgc';

function App() {
  return (
    <Router>
      <MenuComponent />
      <Container>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/card/:id/:hash?" element={<CardStep />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:id" element={<CategoryDetail />} />
          <Route path="/how-to-get-coins" element={<Htgc />} />



        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
