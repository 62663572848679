


import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import SiteLink from './sitelink';
import EmailLink from './email';
import {site, emaildmca, emailcontact} from './config';


// Componente per i Termini e Condizioni (TOS)
const Htgc = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 4 }}>
      <Box sx={{ backgroundColor: '#1a1a1a', color: '#e0e0e0', padding: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom  sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>

          How do credits work?
        </Typography>

        <Typography variant="body1" paragraph>
        Credits will allow you to automatically unlock content without having to do any process outside the site, we introduced them to provide you with a reward!
        </Typography>


        <Typography variant="h4" gutterBottom sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>
          How do I get the credits?
        </Typography>


        <Typography variant="body1" paragraph>
        The accumulation of credits does not require any payment, you will be able to unlock them for free! You just need to use the sitoweb and unlock content... every time 5 content is unlocked automatically you will get a credit!
        </Typography>

      </Box>
    </Container>
  );
};

export default Htgc;
