import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import SiteLink from './sitelink';
import EmailLink from './email';
import {site, emaildmca, emailcontact} from './config';


console.log(site)
// Componente per i Termini e Condizioni (TOS)
const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 4 }}>
      <Box sx={{ backgroundColor: '#1a1a1a', color: '#e0e0e0', padding: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom  sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>
          Terms of Service
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using <SiteLink>{site}</SiteLink>, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, please refrain from using our website.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Eligibility and Account Creation
        </Typography>
        <Typography variant="body1" paragraph>
          You must be at least 18 years old to use <SiteLink>{site}</SiteLink>.
          You are responsible for maintaining the confidentiality of your account credentials.
          You may not create multiple accounts or share your account with others.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Our Services
        </Typography>
        <Typography variant="body1" paragraph>
          <SiteLink>{site}</SiteLink> provides a platform that indexes and organizes leaked data. We do not host the data ourselves, nor do we create or manage it.
          Fees associated with our services are outlined in the complete Terms of Service.
          Prohibited activities include scraping our website, using our services for illegal activities, and using our services in a way that may harm our reputation.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Supported Digital Assets
        </Typography>
        <Typography variant="body1" paragraph>
          Certain Digital Assets or services may not be available or may only be available in certain jurisdictions. We reserve the right to stop supporting certain Digital Assets or services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Changes
        </Typography>
        <Typography variant="body1" paragraph>
          We may make changes to our services, platforms, or the terms themselves. Continued use of our services constitutes your consent to any changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Risks and Liability
        </Typography>
        <Typography variant="body1" paragraph>
          Using our services involves risks. Please read the complete Terms of Service for details on liability, remedies, and limitations.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. No Insurance
        </Typography>
        <Typography variant="body1" paragraph>
          <SiteLink>{site}</SiteLink> is not a bank, and your account is not covered by insurance against losses.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Dispute Resolution
        </Typography>
        <Typography variant="body1" paragraph>
          In case of disputes, arbitration is required.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          For any inquiries related to unlawful content or DMCA takedown requests, please contact us at <EmailLink>{emaildmca}</EmailLink>.
          More information can be regarding DMCA requests can be found under DMCA Policy.
        </Typography>

        <Typography variant="h4" gutterBottom sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>
          Privacy Policy
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          <SiteLink>{site}</SiteLink> ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your information when you use our website.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Information:</strong> We may collect the following personal information:
          IP Address: We collect your IP address when you access our content. This information is not used for tracking purposes but is necessary for the proper functioning of our services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Non-Personal Information:</strong> We may collect non-personal information, such as:
          Usage Data: We use Google Analytics and other third-party tools to collect anonymous usage data. This data helps us improve our services and understand user behavior.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information for the following purposes:
          To provide and maintain our services
          To analyze and improve our website
          To comply with legal obligations
          Provide, operate, and maintain our website
          Improve, personalize, and expand our website
          Understand and analyze how you use our website
          Develop new products, services, features, and functionality
          Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
          Find and prevent fraud
          Log Files
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Disclosure of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose your information to:
          Third-party service providers (e.g., Google Analytics)
          Legal authorities if required by law
          This site is also Protected by reCAPTCHA. Refer to the Google Privacy Policy and Terms of Service.
          We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take reasonable measures to protect your information. Passwords are stored as a hash and not in plain text, IP addresses are also stored in hashed form. However, no method of transmission over the internet or electronic storage is 100% secure.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
          Access, update, or delete your personal information
          Opt-out of data collection (e.g., disable cookies)
          Lodge a complaint with the relevant data protection authority
          If you would like to exercise any of these rights, please contact us at <EmailLink>{emailcontact}</EmailLink>.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information from our records.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Changes to this Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Please review it periodically.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at <EmailLink>{emailcontact}</EmailLink>.
        </Typography>

        <Typography variant="h4" gutterBottom sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>
          DMCA
        </Typography>

        <Typography variant="h6" gutterBottom>
          FOR COPYRIGHT OWNERS
        </Typography>
        <Typography variant="body1" paragraph>
          We do not store any files or images on our server. <SiteLink>{site}</SiteLink> only indexes and links to content provided by other non-affiliated sites. If your copyrighted material has been posted on <SiteLink>{site}</SiteLink> or if hyperlinks to your copyrighted material are returned through our search engine and you want this material removed, you must contact the owners of such sites where the files and images are stored.
        </Typography>
        <Typography variant="body1" paragraph>
          If you believe we can assist you with the complaint, you can contact us on <EmailLink>{emaildmca}</EmailLink>.
        </Typography>
        <Typography variant="body1" paragraph>
          If you wish to report non-copyright-related issues, such as privacy, harassment, unlawful or offensive material, please use the email <EmailLink>{emailcontact}</EmailLink>.
        </Typography>

        <Typography variant="h4" gutterBottom sx={{
                        fontWeight: 'bold',
                        marginBottom: 2, // mb-4 in Tailwind
                    }}>
          18 USC 2257
        </Typography>
        <Typography variant="body1" paragraph>
          ALL PICTURES ARE PRESENTED BY THIRD PARTIES. ALL MODELS ARE 18 YEARS OF AGE OR OLDER IN COMPLIANCE WITH 18 USC 2257
        </Typography>
        <Typography variant="body1" paragraph>
          18 USC 2257 Statement: <SiteLink>{site}</SiteLink> is not a producer (primary or secondary) of any and all of the content found on the website (<SiteLink>{site}</SiteLink>). With respect to the records as per 18 USC 2257 for any and all content found on this site, please kindly direct your request to the site for which the content was produced.
        </Typography>
        <Typography variant="body1" paragraph>
          Pursuant to Title 18 U.S.C. 2257(h)(2)(B)(v) and 47 U.S.C. 230(c), the operators of this website reserve the right to delete content posted by users which operators deem to be indecent, obscene, defamatory or inconsistent with their policies and terms of service. For further assistance and/or information in finding the content’s originating site, please contact our team at : <EmailLink>{emailcontact}</EmailLink>.
        </Typography>
        <Typography variant="body1" paragraph>
          All visitors of this website are required to be over 18 years old (over 21 in some locations).
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
