import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid, InputBase, IconButton, Dialog, DialogContent, DialogTitle, Button, Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCard from './card';
import '../App.css';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

function Main() {
  const [cards, setCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const navigate = useNavigate(); // Hook per la navigazione

  const fetchCards = async () => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/cards`, {
                method: 'GET',
                headers: {
                  'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
                  'Content-Type': 'application/json'
                }
              });
        
      const data = await response.json();
      setCards(data);
    } catch (error) {
      console.error('Errore nel recuperare le carte:', error);
    }
  };

  const searchCards = async (query) => {
    try {
      const response = await fetch(`https://hiddenvideos.vip/api/search-cards?q=${query}`, {
                method: 'GET',
                headers: {
                  'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
                  'Content-Type': 'application/json'
                }
              });
      
      const data = await response.json();
      setCards(data);
    } catch (error) {
      console.error('Errore nella ricerca delle carte:', error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((query) => {
      if (query) {
        setIsSearching(true);
        searchCards(query);
      } else {
        setIsSearching(false);
        fetchCards();
      }
    }, 300),
    [] // Si assicura che la funzione venga creata solo una volta
  );

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, handleSearch]);

  return (
    <>
      <Container>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4} sx={{ mt: 3 }}>
            <div className="search">
              <InputBase
                placeholder="Search..."
                className="inputInput"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <IconButton className="iconButton">
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          {cards.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <CustomCard
                title={card.title}
                category={card.category}
                time={card.time}
                imageUrl={card.imageUrl}
                id={card.id}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Main;
