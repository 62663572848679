import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';

const ClickableCard = ({ image, title, onClick }) => {
  return (
    <Card style={{ width: 300, height: 350, margin: 16, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: 10, backgroundColor: '#000000', border: '1px solid white' }}>
      <CardActionArea onClick={onClick} style={{ flexGrow: 1 }}>
        <CardMedia
          component="img"
          height="280"
          image={image}
          alt={title}
        />
      </CardActionArea>
      <CardContent>
        <Button variant="contained" color="primary" onClick={onClick} fullWidth sx={
        {backgroundColor: '#000000','&:hover':{ backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse
          borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile)
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'}}}>
          {title}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ClickableCard;
